<template>
  <div class="about">
    <div class="banner">
      <img src="../assets/about_banner.jpg" alt="">
      <h1>關於我們</h1>
    </div>
    <div class="main">
      <h2>公司簡介</h2>
      <img src="" alt="">
      <p>聖煒國際成立於2015年，專精於客製化軟體開發與異質系統平台整合。</p>
      <p>主要從事Windows桌面應用程式、網站應用平台、資料庫應用程式、手機APP開發、電子簽核與各類ERP系統整合。</p>
      <p>我們團隊囊括各領域人才，包含電子簽核開發、資深ERP系統人員與資料庫管理師。 </p>
      <p>憑藉優質的服務與經年累月的開發經驗，我們能即時瞭解客戶需求，並提供相關解決方案，讓客戶端盡情享受系統帶來的便利性。</p>

      <!-- <h2>核心能力</h2>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, ad saepe. Sit eveniet accusamus ut culpa odio cupiditate perferendis dolor tenetur, facere ipsum similique assumenda iure neque esse repudiandae est!</p> -->
      <h2>合作夥伴</h2>
      <p>聖煒國際為 U-Office Force 一等一科技加值經銷商，也是客製化軟體之開發商。</p>
      <P><b>我們所提供的服務如下：</b></P>
      <ul class="p1">
        <li class="l1">UOF 電子表單開發與ERP系統、差勤系統、資安系統之整合</li>
        <li class="l1">客製化系統平台開發</li>
        <li class="l1">NFC、RFID 軟硬體整合開發</li>
        <li class="l1">WMS 倉儲管理系統開發</li>
        <li class="l1">條碼應用系統開發</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" >
.about{
  max-width: 940px;
  margin:0 auto;
  padding-left: 20px;
  padding-right: 20px;
  .banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 940px;
      height: 300px;
      object-fit: cover;
    }
  }
}
.main{
  display: flex;
  flex-direction: column;
  justify-content: start;

  ul.p1 {
   list-style-position: inside;
   padding-left: 0;
  }

  li.l1{
    margin-bottom: 15px;
  }

  p{
    margin: 12px 0;
  }
}
</style>
