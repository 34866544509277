<template>
  <div>
    <h1>聯絡方式</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
